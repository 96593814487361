import React from "react";
import footerTop from "../assets/images/footer-top.png";
import parraIcon from "../assets/images/parra-icn.png";
import footerLogo from "../assets/images/footer-logo.png";
import star1 from "../assets/images/star-1.png";
import star2 from "../assets/images/star-2.png";
import parraIconWhite from "../assets/images/parra-icn-white.png";
import topbtn from "../assets/images/top_button.png";
import "../assets/style/footer.css";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaPhoneVolume,
  FaWhatsapp,
  FaWhatsappSquare,
} from "react-icons/fa";

const Footer = () => {
  const top = () => window.scrollTo(0, 0);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="top-footer">
        <div>
          <div className="footer-top-img">
            <img src={footerTop} alt="Footer Top" />
            {/* `${base_url()}assets/images/footer-top.png` */}
          </div>
        </div>

        <div className="parra">
          <img src={parraIcon} alt="Parra Icon" />
          {/* `${base_url()}assets/images/parra-icn.png` */}
        </div>
        <div className="parra1">
          <img src={parraIcon} alt="Parra Icon 1" />
          {/* `${base_url()}assets/images/parra-icn.png` */}
        </div>
      </div>

      <div className="main-footer">
        <div className="container">
          <div className="row">
            {/* NeoSao Logo, Description, Social Icons */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="footer-text">
                <img src={footerLogo} alt="Footer Logo" />
                <p className="">
                  Neosao Services Private Limited is a software development firm
                  with an impressive track record of creating Web and Android
                  Application solutions to support organizational objectives.
                </p>

                <ul className="social-icon mb-3">
                  <li className="facebook mb-3">
                    <a
                      href="https://www.facebook.com/people/NeoSao-Services-PvtLtd/100063918285690/"
                      target="blank"
                    >
                      <span>
                        <FaFacebookF />
                      </span>
                    </a>
                  </li>
                  <li className="twitter mb-3">
                    <a href="https://twitter.com/LtdNeosao" target="blank">
                      <span>
                        <FaTwitter />
                      </span>
                    </a>
                  </li>
                  <li className="instagram mb-3">
                    <a
                      href="https://www.instagram.com/neosaoservices/"
                      target="blank"
                    >
                      <span>
                        <FaInstagram />
                      </span>
                    </a>
                  </li>
                  <li className="LinkedIn mb-3">
                    <a
                      href="https://www.linkedin.com/company/neosao-services-private-limited"
                      target="blank"
                    >
                      <span>
                        <FaLinkedinIn />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Services */}
            <div className="col-md-4 col-sm-4 col-xs-12 mb-3">
              <div className="footer-text">
                <h4>Services</h4>
                <ul>
                  <li>
                    <Link to="/services-contact" onClick={scrollToTop}>
                      <a href="#web-design-development">
                        Web Design &amp; Development
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services-contact" onClick={scrollToTop}>
                      <a href="#">Mobile App Development</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services-contact" onClick={scrollToTop}>
                      <a href="#">Application Maintenance &amp; Management</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services-contact" onClick={scrollToTop}>
                      <a href="#">Digital Marketing</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services-contact" onClick={scrollToTop}>
                      <a href="#">Graphic Design</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services-contact" onClick={scrollToTop}>
                      <a href="#">Technology Consulting</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Address */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="footer-text">
                <h4>Address</h4>
                <ul>
                  <li className="d-flex mb-3">
                    <span className="me-3">
                      <FaMapMarkerAlt />
                    </span>
                    <div style={{ lineHeight: "normal" }}>
                      Ayodhya Tower Bulding No.4, Dabholkar Corner, Kolhapur -
                      416001
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <span className="me-3">
                      <FaMapMarkerAlt />
                    </span>
                    <div style={{ lineHeight: "normal" }}>
                      1st Floor at Workplex, Opp. City Pride Multiplex, near
                      Bhapkar pertol pump, Adinath Society, Pune - Satara Rd,
                      Pune - 411009
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <span className="me-3">
                      <FaPhoneVolume />
                    </span>
                    <div>+91-231 355 0197 / 7038 31 7038</div>
                  </li>
                  <li className="d-flex mb-3">
                    <span className="me-3">
                      <FaEnvelope />
                    </span>
                    <div>support@neosao.com</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="hr"></div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="copy-left">
                <p>© 2019-24 Neosao Services Pvt. Ltd. All rights reserved.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="copy-right">
                <p>
                  <Link to="/privacy" target="_blank">
                    Privacy&nbsp;&nbsp;|&nbsp;&nbsp;
                  </Link>
                  <Link to="/term" target="_blank">
                    Terms&nbsp;&nbsp;|&nbsp;&nbsp;
                  </Link>
                  <Link to="/refund" target="_blank">
                    Refund
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="star">
          <img src={star1} alt="Star 1" />
        </div>
        <div className="star2">
          <img src={star2} alt="Star 2" />
        </div>
        <div className="cricle"></div>
        <div className="cricle1"></div>
        <div className="parra2">
          <img src={parraIconWhite} alt="Parra Icon White" />
        </div>
      </div>

      <a
        href="https://wa.me/917038317038"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          bottom: "20px",
          left: "20px",
          zIndex: 99999,
        }}
      >
        <FaWhatsapp
          size={49}
          color="white"
          style={{
            cursor: "pointer",
            backgroundColor: "#fd6a01",
            borderRadius: "50%",
            padding: "10px",
           display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        />
      </a>

      {/* Back To Top Button */}
      <button onClick={top} id="back-to-top-btn" title="Go to top">
        <img src={topbtn} alt="Back to top" />
      </button>
    </>
  );
};

export default Footer;


//             <div className="col-md-3 col-sm-4 col-xs-12">
//               <div className="footer-text">
//                 <h4>Associations</h4>

//                 <ul
//                   style={{
//                     listStyleType: "circle",
//                   }}
//                 >
//                   <li>
//                     <Link to="" onClick={scrollToTop}>
//                       <a href="#">Member of NASSCOM</a>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="" onClick={scrollToTop}>
//                       <a href="#">Member of ITAK</a>
//                     </Link>
//                   </li>
//                 </ul>
//               </div>

//               <div className="association-images">
//                 <img
//                   src={a1}
//                   alt="Association 1"
//                   style={{ width: "90px", marginRight: "10px" }}
//                 />
//                 <img
//                   src={a2}
//                   alt="Association 2"
//                   style={{ width: "100px", marginRight: "10px" }}
//                 />
//                 <img
//                   src={a3}
//                   alt="Association 3"
//                   style={{ width: "100px", marginRight: "10px" }}
//                 />
//                 <img
//                   src={a4}
//                   alt="Association 4"
//                   style={{ width: "85px", marginRight: "10px" }}
//                 />
//                 <img
//                   src={a5}
//                   alt="Association 5"
//                   style={{ width: "110px", marginRight: "10px" }}
//                 />
//               </div>
//             </div>
//           </div>
//        